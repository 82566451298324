import LoadingComponent from './pages/TheLoading.vue';
import IcToastComponent from './pages/IcToast.vue';
import ToastComponent from './pages/TheToast.vue';
import ConfirmComponent from './pages/TheConfirm.vue';
import AlertComponent from './pages/TheAlert.vue';
import ModalComponent from './pages/TheModal.vue';
import './css/style.css';
import { createApp } from 'vue';
import eventBus from './event-bus';

interface ConfirmFunc {
  (
    options: string | { title?: any; text?: string; html?: string; sureText?: string; cancelText?: string; callback?: any; cancel?: any }
  ): void;
}

interface AlertFunc {
  (options: { title?: any; text?: string; state?: string; html?: string; sureText?: string; callback?: any }): void;
}

interface DialogFn {
  (): {
    $loading: any;
    $icToast: any;
    $toast: any;
    $confirm: ConfirmFunc;
    $alert: AlertFunc;
  };
}

const Loading = {
  install(app: any) {
    const $vm = createApp(LoadingComponent, {});
    const parent = document.createElement('div');
    $vm.mount(parent);
    document.body.append(parent);

    app.config.globalProperties.$loading = {
      //在Vue原型上添加方法，以全局调用
      show(options: string | object) {
        const props = { text: '' };
        // 控制toast显示的方法
        if (typeof options === 'string') {
          // 对参数进行判断
          props.text = options; // 传入props
        } else if (typeof options === 'object') {
          Object.assign(props, options); // 合并参数与实例
        } else if (!options) {
          options = {};
        }
        eventBus.$emit('dialog-loading', options);
      },
      hide() {
        // 控制toast隐藏的方法
        eventBus.$emit('dialog-loading', { show: false });
      },
    };
  },
};
//this.$icToast({text:'aaa',duration:2000,callback:function(){alert('我是回调函数')}})调用方法
const IcToast = {
  install(app: any) {
    const $vm = createApp(IcToastComponent, {});
    const parent = document.createElement('div');
    $vm.mount(parent);
    document.body.append(parent);

    app.config.globalProperties.$icToast = (options: string | object) => {
      const props = { text: '' };
      if (typeof options === 'string') {
        // 对参数进行判断
        props.text = options; // 传入props
      } else if (typeof options === 'object') {
        Object.assign(props, options); // 合并参数与实例
      }
      eventBus.$emit('dialog-ic-toast', options);
    };
  },
};

//this.$toast({text:'aaa',duration:2000,callback:function(){alert('我是回调函数')}})调用方法
const Toast = {
  install(app: any) {
    const $vm = createApp(ToastComponent, {});
    const parent = document.createElement('div');
    $vm.mount(parent);
    document.body.append(parent);

    app.config.globalProperties.$toast = (options: string | object) => {
      const props = { text: '' };
      if (typeof options === 'string') {
        // 对参数进行判断
        props.text = options; // 传入props
      } else if (typeof options === 'object') {
        Object.assign(props, options); // 合并参数与实例
      }
      eventBus.$emit('dialog-toast', options);
    };
  },
};

//使用方法：this.$comfirm({text:'aaa',duration:2000,callback:function(){alert('我是回调函数')}})
const Confirm = {
  install(app: any) {
    const $vm = createApp(ConfirmComponent, {});
    const parent = document.createElement('div');
    $vm.mount(parent);
    document.body.append(parent);

    const oConfirm = document.querySelector('#vueConfirm');
    if (oConfirm) {
      oConfirm.addEventListener('touchmove', function (e) {
        e.stopPropagation();
        e.preventDefault();
      });
    }

    app.config.globalProperties.$confirm = (options: string | object) => {
      const props = { title: '' };

      if (typeof options === 'string') {
        // 对参数进行判断
        props.title = options; // 传入props
      } else if (typeof options === 'object') {
        Object.assign(props, options); // 合并参数与实例
      }

      eventBus.$emit('dialog-confirm', options);
    };
    app.config.globalProperties.$confirmHide = () => {
      eventBus.$emit('dialog-confirm', { show: false });
    };
  },
};
//使用方法：this.$comfirm({text:'aaa',duration:2000,callback:function(){alert('我是回调函数')}})
const Alert = {
  install(app: any) {
    const $vm = createApp(AlertComponent, {});
    const parent = document.createElement('div');
    $vm.mount(parent);
    document.body.append(parent);

    const oEle = document.querySelector('#vueAlert');
    // 阻止遮罩滑动
    if (oEle) {
      oEle.addEventListener('touchmove', function (e) {
        e.stopPropagation();
        e.preventDefault();
      });
    }

    app.config.globalProperties.$alert = (options: string | object | boolean) => {
      // 控制alert开关
      if (typeof options === 'boolean') {
        eventBus.$emit('dialog-alert', { show: false });
        return;
      }

      const props = { title: '' };
      if (typeof options === 'string') {
        // 对参数进行判断
        props.title = options; // 传入props
      } else if (typeof options === 'object') {
        Object.assign(props, options); // 合并参数与实例
      }
      eventBus.$emit('dialog-alert', options);
    };
  },
};

const Modal = {
  install(app: any) {
    const $vm = createApp(ModalComponent, {});
    const parent = document.createElement('div');
    $vm.mount(parent);
    document.body.append(parent);

    const oEle = document.querySelector('#vueModal');
    // 阻止遮罩滑动
    if (oEle) {
      oEle.addEventListener('touchmove', function (e) {
        e.stopPropagation();
        e.preventDefault();
      });
    }

    app.config.globalProperties.$modal = (options: string | object | boolean) => {
      // 控制alert开关
      if (typeof options === 'boolean') {
        eventBus.$emit('dialog-modal', { show: false });
        return;
      }

      const props = { title: '' };
      if (typeof options === 'string') {
        // 对参数进行判断
        props.title = options; // 传入props
      } else if (typeof options === 'object') {
        Object.assign(props, options); // 合并参数与实例
      }
      eventBus.$emit('dialog-modal', options);
    };
  },
};

/**
 * 汇总成一个插件
 */
let useDialog: DialogFn;
const Dialog = {
  install(app: any) {
    app.use(IcToast).use(Toast).use(Loading).use(Confirm).use(Alert).use(Modal);
    const { $loading, $icToast, $confirm, $alert, $toast, $modal } = app.config.globalProperties;
    useDialog = () => {
      return { $loading, $icToast, $confirm, $alert, $toast, $modal };
    };
  },
};
export { Loading, IcToast, Toast, Confirm, Alert, Modal, Dialog, useDialog };
