import './assets/css/base.scss';
import './assets/css/_var.css';

import { createApp } from 'vue';
import { createPinia } from 'pinia';
import { createHead } from '@unhead/vue';
import { Dialog } from './plugins/iscool-dialog';
import axios from 'axios';
import VueAxios from 'vue-axios';

import App from './App.vue';
import router from './router';

const app = createApp(App);
app.use(VueAxios, axios);
app.provide('axios', app.config.globalProperties.axios);
app.use(createHead());
app.use(createPinia());
app.use(router);
app.use(Dialog);

app.mount('#app');
