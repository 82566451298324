<template>
  <div id="vueModal" :class="className">
    <transition name="fade" tag="div">
      <div class="confirm" @click.stop="closeModal" v-show="show"></div>
    </transition>
    <transition name="fade" tag="div">
      <div class="confirm-dialog modal-dialog" v-show="show">
        <div class="flex-1 c-container">
          <p class="c-title" v-if="title" v-html="title"></p>
          <div class="c-html" v-if="html" v-html="html"></div>
        </div>
      </div>
    </transition>
  </div>
</template>
<script>
import eventBus from '../event-bus';
let timer;
let timer2;
export default {
  data() {
    return {
      className: '',
      enabledBgClose: false,
      show: false,
      title: '',
      html: '',
      cancel: null,
      callback: null,
    };
  },
  mounted() {
    eventBus.$on('dialog-modal', this.showItem);
  },
  methods: {
    showItem(options) {
      console.log('showItem', options);
      const setData = key => {
        if (typeof options[key] !== 'undefined') {
          this[key] = options[key];
        }
      };
      const keys = ['className', 'show', 'title', 'enabledBgClose', 'html', 'cancel', 'callback'];
      keys.forEach(v => setData(v));
      if (typeof options.show !== 'boolean') {
        this.show = true;
      }
    },
    closeModal() {
      this.cancel && this.cancel();
      if (!this.enabledBgClose) {
        return;
      }
      this.show = false;
    },
    clearData() {
      //清除上一次进入时所保留的值
      timer2 = setTimeout(() => {
        this.title = '';
        this.text = '';

        this.html = '';
        this.callback = null;
      }, 100);
    },
  },
  watch: {
    show() {
      if (!this.show) {
        timer = setTimeout(() => {
          console.log({ timer });
          this.clearData();
        }, 300);
      } else {
        console.log({ timer, timer2 });
        timer && clearTimeout(timer);
        timer2 && clearTimeout(timer2);
      }
    },
  },
};
</script>
<style scoped lang="scss">
@import '@/assets/css/_mixin.scss';
.fade-enter-active,
.fade-leave-active {
  transition: 0.2s ease;
}
.fade-enter-active,
.fade-leave-to {
  opacity: 0;
  &.confirm-dialog {
    opacity: 1;
    transform: translateY(100%);
  }
}
.fade-enter-to {
  opacity: 1;
  &.confirm-dialog {
    transform: translateY(0%);
  }
}
.confirm {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1006;
  height: 100%;
  width: 100%;
  background: rgba(0, 0, 0, 0.6);
}
.confirm-dialog {
  background-color: #fff;
  transform: translateY(0%);
  @include bottom-gap;
  border-radius: 16px 16px 0px 0px;
  position: fixed;
  display: table;
  z-index: 5000;
  width: 100%;
  bottom: 0;
  right: 0;
  left: 0;
  margin: auto;
  text-align: center;
  overflow: hidden;
}
.c-container {
  padding: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.c-container .c-title {
  color: rgba(0, 0, 0, 0.87);
  font-weight: 500;
  font-size: 18px;
  padding: 24px 16px;
  line-height: 24px;
  word-break: break-all;
  white-space: normal;
}
.c-container .c-con {
  color: rgba(0, 0, 0, 0.87);
  margin: 0 15px;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
}
.btn-confirm {
  text-align: center;
  width: 100%;
  justify-content: space-around;
  height: 88px;
  padding: 0 32px;
}

.btn-confirm span:last-child {
  border-left: 1px solid #eee;
}
.btn-confirm span {
  display: block;
  line-height: 40px;
  height: 40px;
  border-radius: 20px;
  font-weight: 500;
  font-size: 16px;
  background: #ff6800;
  color: rgba(255, 255, 255, 0.87);
}
</style>
