<template>
  <div :class="{ 'lexis-wrap': isLexis }">
    <transition name="slide">
      <div class="wrap" v-show="show">
        <div class="loading flex" :class="{ top: !!top }" :style="{ paddingTop: top }">
          <i v-html="text" :style="{ top: top }"></i>
        </div>
      </div>
    </transition>
  </div>
</template>
<script>
//this.$toast({text:'abc',callback:function(){alert('我是回调函数')}});调用示例
import eventBus from '../event-bus';
let clr;
export default {
  data() {
    return {
      top: '',
      isLexis: false,
      show: false,
      text: '',
      duration: 1500,
      callback: null,
    };
  },
  watch: {
    show() {
      if (!this.show) {
        this.duration = 1500;
        this.callback = null;
      } else {
        this.autoClose();
      }
    },
  },
  mounted() {
    eventBus.$on('dialog-ic-toast', this.showItem);
  },
  methods: {
    showItem(options) {
      const setData = key => {
        if (typeof options[key] !== 'undefined') {
          this[key] = options[key];
        }
      };
      const obj = {
        top: '',
        isLexis: false,
        show: false,
        text: '',
        duration: 1500,
        callback: null,
      };
      const keys = Object.keys(obj);
      keys.forEach(v => setData(v));
      if (typeof options.show !== 'boolean') {
        this.show = true;
      }
    },
    autoClose() {
      if (clr) {
        //如果手速过快，即把上一次的timer清除
        clearTimeout(clr);
      }
      setTimeout(() => {
        this.show = false;
        this.callback && this.callback();
      }, this.duration);
    },
  },
};
</script>
<style scoped lang="scss">
.wrap {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 10000;
  width: 100%;
  height: 100%;
  margin: auto;
  display: flex;
}
.loading {
  width: 100%;
  justify-content: center;
  &.top {
    align-items: flex-start;
    i {
      position: relative;
    }
  }
}
.loading i {
  background: rgba(0, 0, 0, 0.8);
  color: #fff;
  font-size: 14px;
  line-height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  font-style: normal;
  font-weight: normal;
  max-width: 70%;
  min-width: 80px;
  padding: 16px;
  text-align: center;
  // margin-top: -50px;
  border-radius: 16px;
}

.lexis-wrap .loading {
  i {
    border-radius: 8px;
    padding: 6px 16px;
  }
}

.slide-enter-active,
.slide-leave-active {
  -webkit-transition: all 0.12s ease;
  transition: all 0.12s ease;
}
.slide-enter,
.slide-leave-to {
  opacity: 0;
}
</style>
