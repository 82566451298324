<template>
  <div>
    <transition name="slide">
      <div class="wrap" :style="{zIndex: 20000}" v-show="show">
        <div class="loading flex">
          <i v-html="text"></i>
        </div>
      </div>
    </transition>
  </div>
</template>
<script>
//this.$toast({text:'abc',callback:function(){alert('我是回调函数')}});调用示例
import eventBus from '../event-bus';

var clr;
export default {
  data() {
    return {
      zIndex: 1008,
      text: '',
      duration: 1500,
      callback: null,
      show: false,
    };
  },
  watch: {
    show() {
      if (!this.show) {
        this.duration = 1500;
        this.callback = null;
      } else {
        this.autoClose();
      }
    },
  },
  mounted() {
    this.autoClose();
    eventBus.$on('dialog-toast', this.showToast);
  },
  methods: {
    showToast(options) {
      const setData = key => {
        if (options[key]) {
          this[key] = options[key];
        }
      };

      if (typeof options === 'string') {
        this.text = options;
      } else {
        setData('zIndex');
        setData('text');
        setData('duration');
        setData('callback');
      }

      this.show = true;
    },
    autoClose() {
      if (clr) {
        //如果手速过快，即把上一次的timer清除
        clearTimeout(clr);
      }
      setTimeout(() => {
        this.show = false;
        this.callback && this.callback();
      }, this.duration);
    },
  },
};
</script>
<style scoped>
.wrap {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1008;
  width: 100%;
  margin: auto;
  display: table;
  height: 100%;
}
.loading {
  width: 100%;
  justify-content: center;
  height: 100%;
}
.loading i {
  background: rgba(0, 0, 0, 0.8);
  height: 120px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  font-family: 'PingFang SC', 'Source Han Sans CN', 'Noto Sans', 'Helvetica Neue', Helvetica, 'Nimbus Sans L', Arial, 'Liberation Sans' !important;
  font-style: normal;
  font-weight: normal;
  line-height: 1.6;
  max-width: 70%;
  min-width: 80px;
  color: #fff;
  font-size: 14px;
  padding: 8px 24px;
  text-align: center;
  margin-top: -50px;
  border-radius: 4px;
}
.slide-enter-active,
.slide-leave-active {
  -webkit-transition: all 0.12s ease;
  transition: all 0.12s ease;
}
.slide-enter,
.slide-leave-to {
  opacity: 0;
}
</style>
