<template>
  <div id="vueAlert" :class="className">
    <transition-group name="fade" tag="div">
      <div class="confirm" @click.stop="closeAlert" key="1" v-show="show"></div>
    </transition-group>
    <transition-group name="fade" tag="div">
      <div class="confirm-dialog alert-dialog" key="2" v-show="show" :style="{ top }">
        <div class="flex-1 c-container">
          <p class="c-title" v-if="title" v-html="title"></p>
          <div class="c-con" v-if="text">{{ text }}</div>
          <div class="c-con c-state" v-if="state">{{ state }}</div>
          <div v-if="html" v-html="html"></div>
        </div>
        <div class="btn-confirm flex">
          <span class="flex-1" @click.stop="sureFunc()">{{ sureText }}</span>
        </div>
      </div>
    </transition-group>
  </div>
</template>
<script>
import eventBus from '../event-bus';
let timer;
let timer2;
export default {
  data() {
    return {
      className: '',
      show: false,
      title: '',
      enabledBgClose: false,
      top: '50%',
      text: '',
      state: '', // 一句声明文案
      html: '',
      titleColor: '#333',
      textColor: '#999',
      sureText: 'OK',
      sureColor: '#F9940E',
      disableAutoClose: false,
      cancel: null,
      callback: null,
    };
  },
  mounted() {
    eventBus.$on('dialog-alert', this.showItem);
  },
  methods: {
    showItem(options) {
      const setData = key => {
        if (typeof options[key] !== 'undefined') {
          this[key] = options[key];
        }
      };
      const keys = [
        'className',
        'show',
        'title',
        'enabledBgClose',
        'top',
        'text',
        'state',
        'html',
        'titleColor',
        'textColor',
        'sureText',
        'sureColor',
        'disableAutoClose',
        'cancel',
        'callback',
      ];
      keys.forEach(v => setData(v));
      if (typeof options.show !== 'boolean') {
        this.show = true;
      }
    },
    closeAlert() {
      this.cancel && this.cancel();
      if (!this.enabledBgClose) {
        return;
      }
      this.show = false;
    },
    sureFunc() {
      this.callback && this.callback();
      if (!this.disableAutoClose) {
        this.show = false;
      }
    },
    clearData() {
      //清除上一次进入时所保留的值
      timer2 = setTimeout(() => {
        this.title = '';
        this.text = '';
        this.state = '';

        this.html = '';
        this.titleColor = '#F9940E';
        this.textColor = '#999';

        this.sureText = '确定';
        this.sureColor = '#F9940E';

        this.callback = null;
      }, 100);
    },
  },
  watch: {
    show() {
      if (!this.show) {
        timer = setTimeout(() => {
          console.log({ timer });
          this.clearData();
        }, 300);
      } else {
        console.log({ timer, timer2 });
        timer && clearTimeout(timer);
        timer2 && clearTimeout(timer2);
      }
    },
  },
};
</script>
<style scoped lang="scss">
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.2s ease;
}
.fade-enter-active,
.fade-leave-to {
  opacity: 0;
}
.fade-enter-to {
  opacity: 1;
}
.confirm {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1008;
  height: 100%;
  width: 100%;
  background: rgba(0, 0, 0, 0.6);
}
.confirm-dialog {
  background-color: #fff;
  position: fixed;
  display: table;
  z-index: 5000;
  width: calc(100% - 80px);
  max-width: 300px;
  top: 50%;
  transform: translateY(-50%);
  right: 0;
  left: 0;
  margin: auto;
  text-align: center;
  border-radius: 16px;
  overflow: hidden;
}
.c-container {
  padding: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.c-container .c-title {
  color: rgba(0, 0, 0, 1);
  font-weight: 500;
  font-size: 18px;
  padding: 24px 8px 16px;
  line-height: 27px;
  word-break: break-all;
  white-space: normal;
}
.c-container .c-con {
  color: rgba(0, 0, 0, 1);
  margin: 0 15px;
  font-size: 15px;
  font-weight: 400;
  line-height: 22.5px;
}
.c-container .c-state {
  color: rgba(0, 0, 0, 0.87);
  margin: 20px 15px 0;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  white-space: pre-line;
}
.btn-confirm {
  text-align: center;
  width: 100%;
  justify-content: space-around;
  height: 88px;
  padding: 0 32px;
}

.btn-confirm span:last-child {
  border-left: 1px solid #eee;
}
.btn-confirm span {
  display: block;
  line-height: 40px;
  height: 40px;
  border-radius: 20px;
  font-weight: 500;
  font-size: 16px;
  color: rgba(255, 255, 255, 1);
  background: var(--IC-brand-primary-c, rgba(255, 84, 92, 1));
}
</style>
